import { useState } from 'react';
import { Routes ,Route } from "react-router-dom";
import './App.css';
import axios from 'axios';
import Login from './pages/Login'
import Logout from './pages/Logout'
import Signup from './pages/Signup';

function App() {
  const [errors,setErrors] = useState('');

  const loginHandler = ({email,password}) =>{
    const credential = {email,password}
    axios.post(`${process.env.REACT_APP_AUTH_API_BASE_URL}/login`,credential).then(res => {
      const token = res.data.token;
      if(res.data?.status === 101){
        window.location.assign(`${process.env.REACT_APP_ABSAT_URL}/tenantCreate?t=${token}`);
      }else{
        const appUrl = res.data.appUrl;
        const tenantUsername = res.data.tenantUsername;
        localStorage.setItem('token',token);
        window.location.assign(`${appUrl}/${tenantUsername}/token/${token}`);
      }
    }).catch(err => {
      setErrors(' Your email,mobile,username or password is not correct please try again.');
    });
  }
  return (
    <>
      <section className="bg-gray-50 dark:bg-gray-900">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto h-screen lg:py-0">
          { errors  &&   <div className='flex items-center justify-center text-red-400 p-2 my-2  w-full' > {errors} </div>}
          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700" >
            <Routes>
                <Route path='/' element={<Login loginHandler ={loginHandler}/>} />
                <Route path='/user' element={<Login loginHandler ={loginHandler}/>} />
                <Route path='/login' element={<Login loginHandler ={loginHandler}/>} />
                <Route path='/signup' element={<Signup />} />
                <Route path='/logout' element={<Logout/>} />
            </Routes> 
          </div>
        </div>
      </section>
    </>
  );
}

export default App;
