import React, { useState } from 'react'

const Signup = () => {
    const [errors,setErrors] = useState();
    const [user,setUser] = useState({
        username: '',
        name:'',
        jobTitle:'',
        email:'',
        mobile:'',
        password:'',
        default_app_id:null,
        my_apps:null,
        user_class_id:null,
        userSettings:null,
        tenant_id:null,
        company_id:null,
        branch_id:null,
    });

    const formValidate = () =>{

        setErrors({}) 

        if(!user.name){
            errors.name = 'يجب ادخال الاسم '
        }else if(user.name.length < 7){
            errors.name = 'يجب ادخال الاسم كامل ' 
        }

        if(!user.email){
            errors.email = 'يجب ادخال البريد الالكتروني '
        }

        if(!user.mobile){
            errors.mobile = 'يجب ادخال رقم الجوال '
        }else if(user.mobile.length  < 9 ){
            errors.value.mobile = ' يجب ادخال رقم الجوال بشكل صحيح' 
        }

        if(!user.password){
            errors.password = 'يجب ادخال كلمة المرور  '
        }else if(user.password.length  < 6){
            errors.password = 'يجب ان تتكون كلمة المرور علي الاقل من ٦ حروف او ارقام '
        }

        if(Object.keys(errors).length > 0 ){
            return false;
        }
        return true;
    }

    const submitHandler = (e) => {
        e.preventDefault();
        if(formValidate()){
            console.log(user)
           // await userPostHandler();
            
        }
    }
    return (
        <div className="flex flex-col justify-between items-center ">
            <div className="container flex flex-col items-center ">
                <form className=" w-8/12" onSubmit="submitHandler">
                    <div className="flex flex-col  gap-3 rounded-lg bg-slate-500  p-7">
                        <h1 className="text-center text-2xl mb-7 pb-4 border-b border-slate-600">إنشئ حسابك</h1>
                        
                        <div className="flex flex-col z-40">
                            <label 
                                for="name"
                                className="text-slate-100 px-2"
                                >إسمك بالكامل</label>
                            <input 
                                value={user.name}
                                type="text" 
                                placeholder="عبدالله العمري"
                                className="`${ errors?.name && ' border-2 border-red-700 ' } rounded p-2 mx-1`"
                                onChange={(e)=>setUser({...user, name: e.target.value})}
                                />
                            { errors?.name && <span className="px-2 pt-1 text-sm text-red-700">{ errors?.name }</span> }
                        </div>
                        <div className="flex flex-col">
                            <label 
                                for="jobTitle"
                                className="text-slate-100 px-2"
                                >منصبك الوظيفي</label>
                            <input 
                                value={user.jobTitle}
                                type="text" 
                                placeholder="مدير حسابات"
                                className="rounded p-2 mx-1"
                                onChange={(e)=>setUser({...user, jobTitle: e.target.value})}
                                />
                        </div>
                        <div className="flex flex-col">
                            <label 
                                for="email"
                                className="text-slate-100 px-2"
                                >البريد الالكتروني</label>
                            <input 
                                value={user.email}
                                type="email" 
                                placeholder="abdallah-alamari@your-company.com"
                                className="`${ errors?.email && ' border-2 border-red-700 ' } rounded p-2 mx-1`"
                                onChange={(e)=>setUser({...user, email: e.target.value})}
                                />
                                { errors?.email && <span className="px-2 pt-1 text-sm text-red-700">{ errors?.email }</span> }
                        </div>
                        <div className="flex flex-col">
                            <label 
                                for="mobile"
                                className="text-slate-100 px-2"
                                >رقم الجوال</label>
                            <input 
                                value={user.mobile}
                                type="text" 
                                placeholder="9665xxxxxxxx"
                                className="`${ errors?.mobile && ' border-2 border-red-700 ' } rounded p-2 mx-1`"
                                onChange={(e)=>setUser({...user, mobile: e.target.value})}
                                />
                            { errors?.mobile && <span className="px-2 pt-1 text-sm text-red-700">{ errors?.mobile }</span> }
                        </div>
                        <div className="flex flex-col">
                            <label 
                                for="password"
                                className="text-slate-100 px-2"
                                >كلمة المرور</label>
                            <input 
                                value={user.password}
                                type="password" 
                                placeholder="تتكون كلمة المرور علي الاقل من 6 احرف وارقام"
                                className="`${ errors?.password && ' border-2 border-red-700 ' } rounded p-2 mx-1`"
                                onChange={(e)=>setUser({...user, password: e.target.value})}
                                />
                            { errors?.password && <span className="px-2 pt-1 text-sm text-red-700">{ errors?.password }</span> }
                        </div>
                        <div className="flex justify-end z-50">
                            <button type="submit" className=" mt-4 w-fit px-9 py-3 btn-primary font-bold">إرسال</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Signup